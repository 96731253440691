<template>
  <b-row>
    <B-container>
      <b-card>
        <b-row>

          <b-col md="10">
            <b-form-input
              v-model="filter"
              placeholder="Nome"
              class="mb-1"
            />
          </b-col>

          <b-col md="2">
            <b-button
              href="/ecommerce/novo-grupo"
              variant="primary"
              class="ml-auto mb-1 w-100"
            >
              Cadastrar Grupo
            </b-button>
          </b-col>
        </b-row>

        <b-table
          :per-page="12"
          :current-page="page"
          hover
          :filter="filter"
          :fields="[
            { label: 'nome', key: 'name'}
          ]"
          :items="data"
          class="text-center"
          @filtered="onFiltered"
        >
          <template #cell(name)="data">
            <a :href="`ecommerce/grupo/${data.item.id}`"> {{ data.item.name }}</a>
          </template>
        </b-table>

        <b-pagination
          v-model="page"
          :total-rows="length"
          :per-page="12"
          align="center"
          size="sm"
          class="my-2"
        />
      </b-card>
    </B-container>
  </b-row>
</template>

<script>
import {
    BContainer, BRow, BTable, BCard, BButton, BPagination, BFormInput, BCol,
} from 'bootstrap-vue';

import GroupService from '@/service/merchant/mercury/group';

export default {
    components: {
        BContainer, BRow, BTable, BCard, BButton, BPagination, BFormInput, BCol,
    },
    data() {
        return {
            data: [],
            page: 1,
            length: 1,
            filter: '',
        };
    },
    async mounted() {
        this.callLoading(true);
        const { data: { data } } = await GroupService.getAllGroups();
        this.data = data.sort((a, b) => a.name.localeCompare(b.name));
        this.length = this.data.length;
        this.callLoading(false);
    },

    methods: {
        onFiltered(data) {
            this.length = data.length;
        },
    },
};
</script>
