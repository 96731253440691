<template>
  <b-row
    v-if="renderPage"
    lazy
  >
    <b-container fluid>
      <b-tabs
        content-class="mt-2"
        justified
        align="center"
      >
        <b-tab title="Criar novo usuário">
          <new-user-ecommerce />
        </b-tab>
        <b-tab
          title="Usuários"
          lazy
        >
          <list-all-users />

        </b-tab>

        <b-tab
          title="Grupos"
          lazy
        >
          <ListGroups />

        </b-tab>
      </b-tabs>
    </b-container>
  </b-row>
</template>
<script>
import {
    BTabs,
    BTab,
    BRow,
    BContainer,
} from 'bootstrap-vue';
import newUserEcommerce from './NewUserEcommerce.vue';
import listAllUsers from './ListAllUsers.vue';
import ListGroups from './Groups/ListGroups.vue';

export default {
    components: {
        BTabs,
        BTab,
        BRow,
        ListGroups,
        BContainer,
        newUserEcommerce,
        listAllUsers,
    },

    data() {
        return ({
            renderPage: true,
        });
    },

    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ecommerce.scss";
</style>
