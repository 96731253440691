import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BASE_URL;

axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('token')}`;

const registerEc = async data => {
    try {
        return await axios.post(`${BASE_URL}/admin/users/register`, data);
    } catch (err) {
        return err.response;
    }
};

const getAllUsers = async () => {
    try {
        return await axios.get(`${BASE_URL}/backoffice/common/user`);
    } catch (err) {
        return err.response;
    }
};

const findUser = async id => {
    try {
        return await axios.get(`${BASE_URL}/backoffice/common/user/${id}`);
    } catch ({ response }) {
        return response;
    }
};

const updatePermission = async (permissions, id) => {
    try {
        return await axios.put(`${BASE_URL}/admin/users/permissions/${id}`, {
            permissions,
        });
    } catch ({ response }) {
        return response;
    }
};

export const getPermission = async id => {
    try {
        return await axios.get(`${BASE_URL}/admin/users/permissions/${id}`);
    } catch ({ response }) {
        return response;
    }
};

export const updateCustomer = async data => {
    try {
        return await axios.put(`${BASE_URL}/backoffice/user/update`, data);
    } catch ({ response }) {
        return response;
    }
};

const checkEc = async id => {
    try {
        return await axios.get(`${BASE_URL}/customer/details/${id}`);
    } catch ({ response }) {
        return response;
    }
};

export {
    checkEc,
    registerEc,
    getAllUsers,
    findUser,
    updatePermission,
};
