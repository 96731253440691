export const getGroupsOptions = data => {
    const options = [];

    const dataSorted = data.sort((a, b) => a.name.localeCompare(b.name));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of dataSorted) {
        if (item.id !== 1) {
            options.push({
                value: item.id,
                text: item.name,
            });
        }
    }

    options.unshift({
        text: 'Seleciona um grupo',
        value: 1,
    });

    return options;
};
